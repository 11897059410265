import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Yhteystiedot = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Yhteystiedot" />

    <div className="map-wraper full-container">
      <div className="map-wraper-in">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3870.9583035545766!2d25.657467379196078!3d60.980675383681486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e296339dc9edb%3A0x7d376474af5fd7fb!2sScandic%20Lahti%20City!5e0!3m2!1sen!2sfi!4v1574342124770!5m2!1sen!2sfi"
          width="100%"
          height="300"
          frameborder="0"
          allowfullscreen=""
          title="google-map"
        />
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1966.3298785496409!2d25.088525216118796!3d60.47278298207347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e006682053f55%3A0xd582a811e65caf1b!2zU2NhbmRpYyBKw6RydmVucMOkw6Q!5e0!3m2!1sen!2sfi!4v1550665080612"
          width="100%"
          height="300"
          frameborder="0"
          allowfullscreen
          title="google-map"
        /> */}
      </div>
    </div>
    <div className="row pt-10 pb-10">
      <div className="col-md-4 col-md-offset-2">
        <h1>Yhteystiedot</h1>
        <p>
          Pääsihteeri Matti Halme
          <br />
          Koivurinne 4 <br />
          18100 HEINOLA
          <br />
          Puh. 0400 618872
          <br />
          Sähköposti: info(at)shk.fi
        </p>
        <p>
          Suomen hautaustoiminnan keskusliitto ry
          <br />
          Koivurinne 4<br />
          18100 HEINOLA
          <br />
          Puh. 0400 618872
          <br />
          Sähköposti: info(at)shk.fi
          {/* <Link to="/kiitos">kiitos</Link> */}
        </p>

        <div className="spacer-45" />
      </div>
      <div className="col-md-4 col-md-offset-1">
        <div className="contact-form-wrapper ">
          <h3>Lähetä viesti</h3>
          <p className="margin-35">
            Vastaamme mielellämme kysymyksiin ja tiedusteluihin
          </p>
          <form
            name="yhteystietolomake"
            method="POST"
            action="https://formspree.io/info@shk.fi"
            className="contact-form
                            clearfix"
          >
            <input
              type="hidden"
              name="_next"
              value="https://shk.fi/kiitos.html"
            />

            <label for="nimi">Nimesi (vaaditaan)</label>
            <input
              name="nimi"
              type="text"
              id="name"
              className="form-control"
              placeholder=""
            />
            <label for="email">Sähköposti (vaaditaan)</label>
            <input
              name="email"
              type="text"
              id="email"
              className="form-control"
              placeholder=""
            />
            <label for="puhelin">Puhelin</label>
            <input
              name="puhelin"
              type="text"
              id="phone"
              className="form-control"
              placeholder=""
            />
            <label for="viesti">Viestisi (vaaditaan)</label>
            <textarea
              name="viesti"
              cols="2"
              rows="2"
              id="comments"
              className="form-control"
              placeholder=""
            />
            <input
              name="submit"
              type="submit"
              className="button btn-primary"
              value="Lähetä"
            />
          </form>
        </div>
      </div>
    </div>

    <div className="spacer-60" />
  </Layout>
)

export default Yhteystiedot
